import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
// import { useForm, useWatch } from "react-hook-form";
import { api_url } from "../app/api";

import {
  select_filter_lookup,
  toggle_active_filter,
  select_active_filters,
  select_filtered_iso_nums,
} from "../slices/filtersSlice";
import {
  sign_in,
  sign_out,
  select_signed_in,
  select_choosing_filters,
  toggle_choosing_filters,
  toggle_signing_in,
  toggle_signed_in,
  select_signing_in,
  select_email,
  select_password,
  update_email_field,
  update_password_field,
} from "../slices/userSlice";
import {
  select_teeth,
  select_procedures,
  fetchTeeth,
} from "../slices/clinicalSlice";
import { render } from "@testing-library/react";

function handleSubmit() {
  console.log("handleSubmit()");
}

// set state here
function passwordChangeHandler(e: any, props: any) {
  console.log("handleChange()--", e.target.value);
  return e.target.value;
}

function getEmailMessage(email: string) {
  let message: string = "";

  if (!email) {
    return;
  }
  const rule = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  if (!rule.test(email)) {
    message = "please enter a valid email address";
  }

  return message;
}

function getPasswordMessage(password: string) {
  let message: string = "";

  if (!password) {
    return;
  }

  const rules = [
    { regex: /(.*\d.*)/, explanation: "at least one number" },
    { regex: /(.*[a-z].*)/, explanation: "at least one lower case" },
    { regex: /(.*[A-Z].*)/, explanation: "at least one upper case" },
    { regex: /^[a-zA-Z0-9]{8,}$/, explanation: "at least eight characters" },
  ];

  rules.map((rule) => {
    if (!rule.regex.test(password)) {
      message += rule.explanation + "<br></br>";
      console.log(rule.explanation);
    }
  });

  return message;

  // return password;
}

function EmailMessage() {
  const email = useSelector(select_email);
  console.log("email--", email);
  const message = getEmailMessage(email);
  return <div className="email_message">{message}</div>;
}

function PasswordMessage() {
  const password = useSelector(select_password);
  console.log("password--", password);
  const message = getPasswordMessage(password);
  return <div className="password_message">{message}</div>;
}

function SigninCloseButton() {
  const dispatch = useDispatch();

  return (
    <div
      className="signin_close_button"
      onClick={() => dispatch(toggle_signing_in())}
    >
      X
    </div>
  );
}

function submitLogin(
  email: string,
  password: string,
  confirm_password: string,
  first_name: string,
  last_name: string,
  signing_up: boolean,
  setSigningUp: any,
  dispatch: any
) {
  if (signing_up && password !== confirm_password) {
    return;
  }

  console.log("submitLogin running");
  fetch(api_url + "sign-in", {
    method: "POST",
    body: JSON.stringify({
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
      signing_up: signing_up,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      const signing_up = data["signing_up"];
      if (data["success"]) {
        dispatch(toggle_signing_in());
        dispatch(toggle_signed_in());
      }
      // if signing_up true and success true -> signed up
      // if signing_up false and success true -> signed in
      setSigningUp(signing_up);
    });
}

export function Signin() {
  // console.log("password props", password);
  const signing_in = useSelector(select_signing_in);
  console.log("signing_in: " + signing_in);
  const display = signing_in ? "flex" : "none";

  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirm_password, setConfirmPassword] = useState<string>("");
  const [first_name, setFirstName] = useState<string>("");
  const [last_name, setLastName] = useState<string>("");
  const [signing_up, setSigningUp] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div className="modal" style={{ display: display }}>
        <div className="signin_container">
          <div className="signin_close_container">
            <SigninCloseButton />
          </div>
          <div className="signin_title_container">Sign In/Up</div>
          <form onSubmit={handleSubmit}>
            <label>
              {"email"} <br></br>
              <input
                className="white_input"
                name="email"
                type="text"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </label>

            <label>
              {"password"} <br></br>
              <input
                className="white_input"
                name="password"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </label>

            <label style={{ display: signing_up ? "block" : "none" }}>
              {"confirm password"} <br></br>
              <input
                className="white_input"
                name="confirm_password"
                type="password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </label>
            <label style={{ display: signing_up ? "block" : "none" }}>
              {"first name"} <br></br>
              <input
                className="white_input"
                name="first_name"
                type="text"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </label>
            <label style={{ display: signing_up ? "block" : "none" }}>
              {"last name"} <br></br>
              <input
                className="white_input"
                name="last_name"
                type="text"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </label>
          </form>
          <div
            className="procedure_item_container"
            onClick={(e) =>
              submitLogin(
                email,
                password,
                confirm_password,
                first_name,
                last_name,
                signing_up,
                setSigningUp,
                dispatch
              )
            }
          >
            Submit
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect()(Signin);

/*const mapStateToProps = (state) => {
  return {
    password: state.password,
  };
};

// Map actions to props
const mapDispatchToProps = (dispatch) => {
  return {
    onPasswordChange: () => {
      dispatch(update_password_field());
    },
  };
};*/

/*let renderCount = 0;
function PasswordWatched(e) {
  const dispatch = useDispatch();
  // dispatch(update_password_field(e.target.value));
  const password = "";

  return <div className="password_message">{(password, renderCount++)}</div>;
}*/

/*function SigninModal(props) {
  var password = props.password;
  console.log("password props", props.password);
  const signing_in = useSelector(select_signing_in);
  console.log("signing_in: " + signing_in);
  const display = signing_in ? "flex" : "none";
  const dispatch = useDispatch();

  return (
    <div className="modal" style={{ display: display }}>
      <div className="signin_container">
        <div className="signin_close_container">
          <SigninCloseButton />
        </div>
        <div className="signin_title_container">Sign In</div>
        <FormDiv onPasswordChange={this.props.onPasswordChange} />
      </div>
    </div>
  );
}*/

/*function FormDiv(props) {
  const onSubmit = (data) => console.log(data);
  const { register, control, handleSubmit } = useForm();
  const onPasswordChange = props.onPasswordChange;
  const password = props.password;
  console.log("password--", password);

  return (
    {...register("email", {
            required: true,
            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          })}
    <form onSubmit={handleSubmit}>
      <label>
        {"email"} <br></br>
        <input
          {...register("email")}
          type="text"
          onChange={passwordChangeHandler}
        />
      </label>
      <label>
        {"password"} <br></br>
        <input
          {...register("password")}
          type="password"
          onChange={onPasswordChange}
        />
      </label>
      <div className="password_message">{password}</div>
      <input type="submit" />
    </form>
  );
}*/
