import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { RootState, AppDispatch } from "../app/store";
import { fetchFilters } from "../slices/filtersSlice";
import { fetchTeeth, fetchThreeDFiles } from "../slices/clinicalSlice";

function Setup() {
  const dispatch = useDispatch();

  const teethStatus = useSelector(
    (state: RootState) => state.clinical.teeth_status
  );
  const threeDFilesStatus = useSelector(
    (state: RootState) => state.clinical.threeD_files_status
  );
  const filtersStatus = useSelector((state: RootState) => state.filters.status);

  useEffect(() => {
    if (teethStatus === "idle") {
      dispatch(fetchTeeth());
    }
    if (threeDFilesStatus === "idle") {
      dispatch(fetchThreeDFiles());
    }
    if (filtersStatus === "idle") {
      dispatch(fetchFilters());
    }
  }, [filtersStatus, dispatch]);

  return (
    <React.Fragment>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="*"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap"
        rel="stylesheet"
      ></link>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="*"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Hina+Mincho&display=swap"
        rel="stylesheet"
      ></link>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="*"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&display=swap"
        rel="stylesheet"
      ></link>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="*"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
        rel="stylesheet"
      ></link>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="*"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap"
        rel="stylesheet"
      ></link>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="*"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet"
      ></link>
    </React.Fragment>
  );
}

export { Setup };
