export interface Criterion {
  category: string;
  criterion_name: string;
  eval: number;
  remarks: string;
  total_grade: number;
  rubric_row: [string, string, string];
}

export interface Evaluation {
  criteria: Criterion[];
  eval_date: {
    [key: string]: number;
  };
  evaluator_name: string;
}

export interface Procedure {
  course: string;
  due_date: {
    [key: string]: number;
  };
  evaluations: Evaluation[];
  name: string;
  tooth_iso: number;
  tooth_uns: number;
  eval_type: string;
}

export interface Sort {
  name: string;
  sort_fun: (a: Procedure, b: Procedure) => number;
}

export interface ProceduresContainer {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  procedures: Procedure[];
}

export interface Tooth {
  iso: number;
  uns: number;
  procedures: Procedure[];
}

export interface Clinical {
  teeth_status: "idle" | "loading" | "succeeded" | "failed";
  threeD_files_status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  teeth: Tooth[];
  sort_by_name: string;
  ascending: boolean;
  procedures: Procedure[];
  eval_procedure: Procedure | null;
  criteria: Criterion[];
  threeD_files: string[];
}

export interface Filters {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  active_filters: string[];
  filter_lookup: { [key: string]: number[] };
  filtered_iso_nums: number[];
}

export interface User {
  email: string;
  password: string;
  signed_in: boolean;
  signing_in: boolean;
  choosing_filters: boolean;
  choosing_sort: boolean;
  view: string;
}

export interface Insight {
  procedure_name: string;
  filter_name: string;
  avg_grade: number;
}

export interface ThreeD_File {
  file_name: string;
  folder: string;
  visibility: string[];
  display_name: string;
}

const LOCAL = "http://127.0.0.1:5000/";
const DEV = "https://api.molar.ai/";
const LIVE = "https://api.molar.ai/";
export const api_url = LIVE;

export async function getTeeth(): Promise<Tooth[]> {
  const response = await fetch(api_url + "teeth");
  const body = await response.json();
  const teeth = body["teeth"];
  return teeth;
}

export async function getThreeDFiles(): Promise<ThreeD_File[]> {
  const response = await fetch(api_url + "threeD_files");
  const body = await response.json();
  const threeD_files = body["threeD_files"];
  return threeD_files;
}

export async function getCameraFiles(): Promise<string[]> {
  const response = await fetch(api_url + "camera_files");
  const body = await response.json();
  const camera_files: string[] = body["camera_files"];
  return camera_files.filter((f) => f != ".gitkeep");
}

export async function getFilters(): Promise<Filters["filter_lookup"]> {
  const response = await fetch(api_url + "filters");
  const body = await response.json();
  const filter_lookup = body["filters"];
  return filter_lookup;
}

export function getFilteredISONums(
  active_filters: Filters["active_filters"], // string[]
  filter_lookup: Filters["filter_lookup"] // dict
) {
  // start refactor
  const max_mand_iso = active_filters
    .filter((x) => x == "mandibular" || x == "maxillary")
    .map((x) => filter_lookup[x])
    .flat();
  //console.log("max_mand_iso--", max_mand_iso);
  const left_right_iso = active_filters
    .filter((x) => x == "left" || x == "right")
    .map((x) => filter_lookup[x])
    .flat();
  //console.log("left_right_iso--", left_right_iso);

  const tens_filter_intersection = (
    max_mand_iso.length != 0 ? max_mand_iso : [10, 20, 30, 40]
  ).filter((x) =>
    (left_right_iso.length != 0 ? left_right_iso : [10, 20, 30, 40]).includes(x)
  );
  // end refactor

  const active_iso_nums = active_filters.map((x) => filter_lookup[x]).flat();
  const tens =
    tens_filter_intersection.length === 0
      ? [10, 20, 30, 40]
      : tens_filter_intersection;
  //console.log("tens--", tens);
  const ones_list = active_iso_nums.filter((x) => x % 10 !== 0);
  const ones = ones_list.length === 0 ? [1, 2, 3, 4, 5, 6, 7, 8] : ones_list;
  //console.log("ones--", ones);

  const filtered_tooth_iso: number[] = [];
  tens.forEach((x) => {
    ones.forEach((y) => {
      //console.log("x--", x);
      //console.log("y--", y);
      const sum = x + y;
      //console.log("sum--", sum);
      filtered_tooth_iso.push(sum);
    });
  });
  // console.log(filter_tooth_iso)
  return filtered_tooth_iso;
}

export function getGradeColor(total_grade: number) {
  const percentColors = [
    { pct: 0.0, color: { r: 0xfd, g: 0x88, b: 0x88 } },
    { pct: 0.7, color: { r: 0xfd, g: 0xfd, b: 0x88 } },
    { pct: 1.0, color: { r: 0x88, g: 0xfd, b: 0x88 } },
  ];

  const pct = total_grade / 100;
  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  var lower = percentColors[i - 1];
  var upper = percentColors[i];
  var range = upper.pct - lower.pct;
  var rangePct = (pct - lower.pct) / range;
  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;
  var color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };
  return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
  // or output as hex if preferred
}

export function getSortFuns(sort_name: string) {
  return [...sort_types]
    .filter((x) => x.name == sort_name)
    .map((x) => x.sort_fun)[0];
}

export const sort_types: Sort[] = [
  {
    name: "course",
    sort_fun: (a: Procedure, b: Procedure) => {
      if (a.course < b.course) {
        return -1;
      } else if (a.course > b.course) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "total_grade",
    sort_fun: (a: Procedure, b: Procedure) => {
      const grade_a = a["evaluations"][0]["criteria"][0]["total_grade"];
      const grade_b = b["evaluations"][0]["criteria"][0]["total_grade"];

      if (grade_a < grade_b) {
        return -1;
      } else if (grade_a > grade_b) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "due_date",
    sort_fun: (a: Procedure, b: Procedure) => {
      const date_a = a["due_date"]["$date"];
      const date_b = b["due_date"]["$date"];

      //console.log("date getting sorted", date_a);
      if (date_a < date_b) {
        return -1;
      } else if (date_a > date_b) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: "tooth_uns",
    sort_fun: (a: Procedure, b: Procedure) => a.tooth_uns - b.tooth_uns,
  },
  {
    name: "rev",
    sort_fun: (a: Procedure, b: Procedure) => a.tooth_uns - b.tooth_uns,
  },
];

export function extractDate(procedure: Procedure) {
  const due_date = procedure["due_date"];
  const date = due_date["$date"];
  //console.log("date_hey--", date);
  const date_time = new Date(date);
  //console.log("date_time--", date_time);

  return date_time;
}
