import { createSlice } from "@reduxjs/toolkit";
import { User } from "../app/api";
import { RootState } from "../app/store";

const initialState: User = {
  signed_in: false,
  signing_in: false,
  choosing_filters: false,
  choosing_sort: false,
  view: "threeD",
  email: "",
  password: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    sign_in: (state) => {
      state.signed_in = true;
    },
    sign_out: (state) => {
      state.signed_in = false;
    },
    toggle_signed_in: (state) => {
      state.signed_in = !state.signed_in;
    },
    toggle_signing_in: (state) => {
      state.signing_in = !state.signing_in;
    },
    toggle_choosing_filters: (state) => {
      state.choosing_filters = !state.choosing_filters;
    },
    toggle_choosing_sort: (state) => {
      state.choosing_sort = !state.choosing_sort;
    },
    update_email_field: (state, action) => {
      console.log("update_email_field--", action);
      state.email = action.payload;
    },
    update_password_field: (state, action) => {
      console.log("update_password_field--", action);
      state.password = action.payload;
    },
    toggle_view: (state, action) => {
      console.log("view--", action.payload);
      const view = action.payload;
      if (state.view == view) {
        state.view = "threeD";
      } else {
        state.view = view;
      }
    },
  },
});

export const {
  sign_in,
  sign_out,
  toggle_signed_in,
  toggle_signing_in,
  toggle_choosing_filters,
  toggle_choosing_sort,
  update_email_field,
  update_password_field,
  toggle_view,
} = userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTeeth(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
/*export const fetchTeeth = (user_email) => (dispatch) => {
  setTimeout(async () => {
    const response = await fetch("http://127.0.0.1:5000/teeth");
    const body = await response.json();
    const teeth = body["teeth"];
    dispatch(update_teeth(teeth));
  }, 1000);
};

export const fetchFilters = (user_email) => (dispatch) => {
  console.log("fetchFilters--");
  setTimeout(async () => {
    const response = await fetch("http://127.0.0.1:5000/filters");
    const body = await response.json();
    const filters = body["filters"];
    //console.log("async--", filters);
    dispatch(update_filters(filters));
    //dispatch(add_active_filter("all"));
  }, 1000);
};*/

export const select_signed_in = (state: RootState) => state.user.signed_in;
export const select_signing_in = (state: RootState) => state.user.signing_in;
export const select_choosing_filters = (state: RootState) =>
  state.user.choosing_filters;
export const select_choosing_sort = (state: RootState) =>
  state.user.choosing_sort;
export const select_email = (state: RootState) => state.user.email;
export const select_password = (state: RootState) => state.user.password;
export const select_view = (state: RootState) => state.user.view;

export default userSlice.reducer;
