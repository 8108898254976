import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { RootState, AppDispatch } from "../app/store";

import {
  select_filter_lookup,
  toggle_active_filter,
  select_active_filters,
  select_filtered_iso_nums,
  fetchFilters,
  select_filters_state,
} from "../slices/filtersSlice";
import {
  sign_in,
  sign_out,
  select_signed_in,
  select_choosing_filters,
  select_choosing_sort,
  toggle_choosing_filters,
  toggle_choosing_sort,
  select_view,
} from "../slices/userSlice";
import {
  select_teeth,
  select_procedures,
  sort_procedures,
  select_sort_by_name,
  select_clinical_teeth_status,
  select_ascending,
  toggle_sort_by_name,
  fetchTeeth,
  toggle_ascending,
  searchProcedure,
} from "../slices/clinicalSlice";
import { Teeth } from "./teeth";
import { Filters, Procedure, Sort, getGradeColor } from "../app/api";

function ProceduresFilterContainer() {
  const dispatch = useDispatch();

  const filter_lookup = useSelector(select_filter_lookup);
  const choosingFilters = useSelector(select_choosing_filters);
  const display = choosingFilters ? "flex" : "none";
  const filter_names = filter_lookup ? Object.keys(filter_lookup).sort() : [];
  const active_filters = useSelector(select_active_filters);

  return (
    <div className="procedures_filter_container">
      <div
        className="procedures_filter_button button"
        onClick={(e) => dispatch(toggle_choosing_filters())}
      >
        Filter
      </div>
      <div
        className="procedures_filter_dropdown_container"
        style={{ display: display }}
      >
        {filter_names &&
          filter_names.map((filter_name) => {
            console.log("filter_names--", filter_names);

            console.log("filter_name--", filter_name);
            return (
              <div
                className={
                  active_filters.includes(filter_name)
                    ? "procedures_filter_dropdown_option active_filter"
                    : "procedures_filter_dropdown_option"
                }
                onClick={() => dispatch(toggle_active_filter(filter_name))} // add to active_filters in store
              >
                {filter_name}
              </div>
            );
          })}
      </div>
    </div>
  );
}

function ProceduresSortContainer() {
  const dispatch = useDispatch();

  const filters = useSelector(select_filter_lookup);
  const choosingSort = useSelector(select_choosing_sort);
  const display = choosingSort ? "flex" : "none";
  const sort_names: string[] = [
    "course",
    "total_grade",
    "due_date",
    "tooth_uns",
  ];
  const sortByName = useSelector(select_sort_by_name);
  const ascending = useSelector(select_ascending);

  return (
    <div className="procedures_filter_container">
      <div
        className="procedures_filter_dropdown_container"
        style={{ display: display }}
      >
        {sort_names &&
          sort_names.map((sort_name) => {
            console.log("sort_name--", sort_name);
            return (
              <div
                className={
                  sortByName == sort_name
                    ? "procedures_filter_dropdown_option button active_filter"
                    : "procedures_filter_dropdown_option"
                }
                onClick={() => {
                  dispatch(toggle_sort_by_name(sort_name));
                }} // add to active_filters in store
              >
                {sort_name}
              </div>
            );
          })}
      </div>
      <div
        className="sort_direction_button button"
        onClick={() => {
          dispatch(toggle_ascending());
        }}
      >
        {ascending ? "↑" : "↓"}
      </div>
      <div
        className="procedures_sort_button button"
        onClick={(e) => dispatch(toggle_choosing_sort())}
      >
        Sort
      </div>
    </div>
  );
}

function filterProcedures(
  filtered_iso_nums: Filters["filtered_iso_nums"],
  procedure: Procedure
) {
  const tooth_iso = procedure["tooth_iso"];
  console.log("filterProcedure.filtered_iso_nums", filtered_iso_nums);
  console.log("filterProcedure.tooth_iso", tooth_iso);
  return filtered_iso_nums.includes(tooth_iso);
}

/*function getGradeColor(total_grade: number) {
  const threshold = 70;
  const delta_to_threshold = Math.abs(threshold - total_grade);
  const below_threshold =
    total_grade < threshold ? 60 + (delta_to_threshold / 100) * 23 : 0;
  const above_threshold =
    total_grade > threshold ? 60 + (delta_to_threshold / 100) * 23 : 0;
  let color = `rgb(${170 + below_threshold},${170 + above_threshold},150)`;
  return color;
}*/

export function Procedures() {
  const procedures = useSelector(select_procedures);

  const dispatch = useDispatch();
  const teethStatus = useSelector(
    (state: RootState) => state.clinical.teeth_status
  );
  const filtersStatus = useSelector((state: RootState) => state.filters.status);
  const teeth = useSelector((state: RootState) => state.clinical.teeth);
  const filtered_iso_nums = useSelector(select_filtered_iso_nums);

  console.log("filtersStatus--", filtersStatus);
  console.log("teethStatus--", teethStatus);
  console.log("teeth--", teeth);
  const view = useSelector(select_view);
  const display = view == "assignments" ? "flex" : "none";

  return (
    <React.Fragment>
      <div className="procedures_navbar_container" style={{ display }}>
        <ProceduresFilterContainer />
        <ProceduresSortContainer />
      </div>
      <div className="procedures_container" style={{ display }}>
        {procedures &&
          procedures
            .filter((p) => filterProcedures(filtered_iso_nums, p))
            .map((procedure: Procedure) => {
              console.log("yo procedure--", procedure);
              const due_date = procedure["due_date"];
              const date = due_date["$date"];
              console.log("date--", date);
              const date_time = new Date(date);
              console.log("date_time--", date_time);
              const formatted_date = `${
                date_time.getMonth() + 1
              }/${date_time.getDate()}/${date_time.getFullYear()}`;

              const total_grade =
                procedure["evaluations"][0]["criteria"][0]["total_grade"];
              const total_grade_color = getGradeColor(total_grade);
              return (
                <div
                  className="procedure_container"
                  onClick={(e) => {
                    const due_date_ms = procedure["due_date"]["$date"];
                    const tooth_uns = procedure["tooth_uns"];
                    const name = procedure["name"];
                    const eval_type = procedure["eval_type"];
                    console.log("due_date procedures--", due_date_ms);

                    dispatch(
                      searchProcedure({
                        due_date_ms,
                        tooth_uns,
                        name,
                        eval_type,
                      })
                    );
                  }}
                >
                  <div className="procedure_item_container">
                    {procedure["name"]}
                  </div>
                  <div className="procedure_item_container">
                    #{procedure["tooth_uns"]}
                  </div>
                  <div className="procedure_item_container">
                    {procedure["course"]}
                  </div>
                  <div
                    className="procedure_item_container"
                    style={{
                      backgroundColor: total_grade_color,
                      color: "#333333",
                      fontWeight: "bold",
                    }}
                  >
                    {procedure["evaluations"][0]["criteria"][0]["total_grade"]}%
                  </div>
                  <div className="procedure_item_container">
                    {formatted_date}
                  </div>
                </div>
              );
            })}
      </div>
    </React.Fragment>
  );
}
