import React, {
  MouseEventHandler,
  useEffect,
  useState,
  useRef,
  useCallback,
  Suspense,
} from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import type { RootState, AppDispatch } from "../app/store";
import { api_url, getCameraFiles, ThreeD_File } from "../app/api";

import { select_view } from "../slices/userSlice";

import LoadingBar from "react-top-loading-bar";

function NavigationHeader(props: any) {
  const selected_folder: string = props.selected_folder;
  const setSelectedFolder: any = props.setSelectedFolder;

  return <div className="file_option">{"Photos"}</div>;
}

function CameraFileOption({
  fileName,
  setSelectedFile,
  progress_bar_ref,
}: {
  fileName: string;
  setSelectedFile: any;
  progress_bar_ref: any;
}) {
  return (
    <div
      className="file_option button"
      onClick={() => {
        console.log("setting fileName to " + fileName);
        setSelectedFile(fileName);
      }}
    >
      {fileName}
    </div>
  );
}

function Photo(props: any) {
  const selected_file_name: string = props.selected_file_name;
  const img_src = api_url + "camera/" + selected_file_name;

  return (
    <div className="photo_container">
      <img src={img_src} height={300} width={300} />
    </div>
  );
}

export function Camera() {
  const view = useSelector(select_view);

  const [camera_files, setCameraFiles] = useState<string[]>([]);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [selected_file_name, setSelectedFileName] = useState<string>();

  const display = view == "camera" ? "flex" : "none";
  const [fileName, setFileName] = useState(null);
  const ref = useRef(null);

  // setUpDefaultLoadingManager(ref);

  useEffect(() => {
    console.log("uploaded", uploaded);
    getCameraFiles().then((camera_files) => {
      setCameraFiles(camera_files);
      // setUploaded(false);
    });
  }, [uploaded]);

  useEffect(() => {
    console.log("selected_file_name", selected_file_name);
  }, [selected_file_name]);

  return (
    <div className="camera_container" style={{ display }}>
      <div className="camera_navigation_container">
        <NavigationHeader />
        {camera_files.map((camera_file) => (
          <CameraFileOption
            fileName={camera_file}
            setSelectedFile={setSelectedFileName}
            progress_bar_ref={ref}
          />
        ))}
      </div>
      <Photo selected_file_name={selected_file_name} />
    </div>
  );
}
