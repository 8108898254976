import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { select_filtered_iso_nums } from "../slices/filtersSlice";
import {
  select_teeth,
  select_clinical_teeth_status,
} from "../slices/clinicalSlice";
import { select_view } from "../slices/userSlice";

export function Teeth() {
  console.log("Teeth running");
  const dispatch = useDispatch();

  const teeth = useSelector(select_teeth);
  const filtered_iso_nums = useSelector(select_filtered_iso_nums);

  console.log("filtered_iso_nums--", filtered_iso_nums);
  console.log("teeth--", teeth);

  const view = useSelector(select_view);
  const display = view == "assignments" ? "block" : "none";

  return (
    <React.Fragment>
      <div className="teeth_container" style={{ display }}>
        <div className="arch_container">
          {teeth &&
            teeth.slice(0, 16).map((tooth) => {
              console.log(
                'tooth["procedures"].length--',
                tooth["procedures"].length
              );
              return (
                <div className="tooth_container">
                  <div
                    className={
                      tooth["procedures"].length &&
                      filtered_iso_nums &&
                      filtered_iso_nums.includes(tooth["iso"])
                        ? "tooth_title_container active_tooth"
                        : "tooth_title_container"
                    }
                  >
                    {tooth["uns"]}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="arch_container">
          {teeth &&
            teeth
              .slice(-16)
              .reverse()
              .map((tooth) => {
                console.log("mandibular running brah");
                return (
                  <div className="tooth_container">
                    <div
                      className={
                        tooth["procedures"].length &&
                        filtered_iso_nums &&
                        filtered_iso_nums.includes(tooth["iso"])
                          ? "tooth_title_container active_tooth"
                          : "tooth_title_container"
                      }
                    >
                      {tooth["uns"]}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </React.Fragment>
  );
}
