import React, { Component } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  sign_in,
  sign_out,
  select_signed_in,
  toggle_signed_in,
  toggle_signing_in,
  toggle_view,
  select_view,
} from "../slices/userSlice";

import { api_url } from "../app/api";

type AppProps = {
  signed_in: boolean;
};

function AccountButton() {
  const signed_in = useSelector(select_signed_in);

  console.log("signed_in: " + signed_in);
  if (signed_in) {
    return <span>Sign Out</span>;
  }
  return <span>Sign In</span>;
}

export function Navbar() {
  let styles = {
    fontSize: 50,
    fontWeight: "bold",
  };
  const signed_in = useSelector(select_signed_in);
  const dispatch = useDispatch();
  const view = useSelector(select_view);

  return (
    <React.Fragment>
      <div className="navbar_container">
        <div className="title_container">
          <a className="title_font" href="#">
            molar
          </a>
        </div>
        <div className="nav_item_container">
          <div
            className={
              "nav_item button" + (view == "threeD" ? " active_filter" : "")
            }
            onClick={() => dispatch(toggle_view("threeD"))}
          >
            3D Library
          </div>

          <div
            className={
              "nav_item button" + (view == "camera" ? " active_filter" : "")
            }
            onClick={() => dispatch(toggle_view("camera"))}
          >
            Camera
          </div>

          {/** 
           * <div
            className={
              "nav_item button" +
              (view == "Assignments" ? " active_filter" : "")
            }
            onClick={() => dispatch(toggle_view("assignments"))}
          >
            Assignments
          </div>
          <div
            className={
              "nav_item button" + (view == "insights" ? " active_filter" : "")
            }
            onClick={() => dispatch(toggle_view("insights"))}
          >
            Insights
          </div>
          <div
            className={
              "nav_item button" + (view == "create" ? " active_filter" : "")
            }
            onClick={() => dispatch(toggle_view("create"))}
          >
            Create
          </div>
          <div
            className={
              "nav_item button" + (view == "grades" ? " active_filter" : "")
            }
            onClick={() => dispatch(toggle_view("grades"))}
          >
            Grades
          </div>
           * 
          */}

          <div
            id="account_button"
            className="nav_item button account"
            onClick={() => {
              if (signed_in) {
                fetch(api_url + "sign-out", {
                  method: "POST",
                });
              }
              dispatch(toggle_signing_in());
            }}
          >
            <AccountButton></AccountButton>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
