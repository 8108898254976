import React, {
  MouseEventHandler,
  useEffect,
  useState,
  useRef,
  useCallback,
  Suspense,
} from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import type { RootState, AppDispatch } from "../app/store";
import {
  getFilteredISONums,
  getGradeColor,
  Insight,
  sort_types,
  getSortFuns,
  extractDate,
  api_url,
  getThreeDFiles,
  ThreeD_File,
} from "../app/api";

import {
  select_filter_lookup,
  toggle_active_filter,
  select_active_filters,
  select_filtered_iso_nums,
  fetchFilters,
  select_filters_state,
} from "../slices/filtersSlice";
import {
  sign_in,
  sign_out,
  select_signed_in,
  select_choosing_filters,
  select_choosing_sort,
  toggle_choosing_filters,
  toggle_choosing_sort,
  select_view,
} from "../slices/userSlice";

import { Teeth } from "./teeth";
import { Filters, getFilters, Procedure, Sort, Criterion } from "../app/api";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";

import { Canvas, useLoader, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, RoundedBox, useProgress } from "@react-three/drei";
import { BoxGeometry, BufferGeometry, DefaultLoadingManager } from "three";

import LoadingBar from "react-top-loading-bar";

import { useDropzone } from "react-dropzone";

function ToothShape() {
  const myMesh: any = React.useRef();

  const [geometry, setGeometry] = useState<BufferGeometry>();

  useEffect(() => {
    const stlLoader = new STLLoader();
    stlLoader.load(api_url + "tooth_stl", (geo) => {
      setGeometry(geo);
      console.log("setting_geo-", geo);
    });
  }, []);

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();
    myMesh.current.rotation.x = a;
  });

  return (
    <mesh ref={myMesh}>
      <primitive object={geometry} attach="geometry" />
      <meshPhongMaterial color="beige" />
    </mesh>
  );
}

function CameraControls() {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls

  const {
    camera,
    gl: { domElement },
  } = useThree();

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls: any = useRef();
  useFrame((state) => controls.current.update());
  return (
    <OrbitControls
      ref={controls}
      args={[camera, domElement]}
      enableZoom={true}
      maxAzimuthAngle={Math.PI}
      maxPolarAngle={Math.PI}
      minAzimuthAngle={-Math.PI}
      minPolarAngle={0}
    />
  );
}

function Lighting() {
  return (
    <React.Fragment>
      <ambientLight intensity={0.5} />
      <directionalLight intensity={0.2} position={[80, 80, 0]} />
      <directionalLight intensity={0.2} position={[-80, 0, 0]} />
      <directionalLight intensity={0.5} position={[0, 80, 0]} />
      <directionalLight intensity={0.5} position={[0, 0, 80]} />
    </React.Fragment>
  );
}

function Mesh({ geometry }: { geometry: BufferGeometry | null }) {
  return (
    <React.Fragment>
      <Suspense fallback={<Box />}>
        <mesh scale={1} position={[0, 0, 0]} rotation={[0, 0, 0]}>
          <primitive object={geometry} attach="geometry" />
          <meshPhongMaterial color="grey" />
        </mesh>
      </Suspense>
    </React.Fragment>
  );
}

function useSTL(fileName: string, setGeometry: any) {
  useEffect(() => {
    const stlLoader = new STLLoader();
    stlLoader.load(api_url + fileName, (geo) => {
      setGeometry(geo);
      console.log("setting_geo-", geo);
    });
  });
}

function NavigationHeader(props: any) {
  const selected_folder: string = props.selected_folder;
  const setSelectedFolder: any = props.setSelectedFolder;

  return (
    <div
      className="file_option"
      onClick={() => {
        if (selected_folder) {
          setSelectedFolder(null);
        }
      }}
    >
      {!selected_folder && "3D Library"}
      {selected_folder && "Back"}
    </div>
  );
}

function ThreeDFolderOption({
  folderName,
  setSelectedFolder,
}: {
  folderName: string;
  setSelectedFolder: any;
}) {
  return (
    <div
      className="file_option button"
      onClick={() => {
        console.log("setting folderName to " + folderName);
        setSelectedFolder(folderName);
      }}
    >
      {folderName}
    </div>
  );
}

function ThreeDFileOption({
  fileName,
  setFileName,
  progress_bar_ref,
}: {
  fileName: string;
  setFileName: any;
  progress_bar_ref: any;
}) {
  return (
    <div
      className="file_option button"
      onClick={() => {
        console.log("setting fileName to " + fileName);
        setFileName(fileName);
        progress_bar_ref.current.continuousStart(20);
      }}
    >
      {fileName}
    </div>
  );
}

function Box() {
  const mesh: any = useRef();

  useFrame(() => {
    mesh.current.rotation.x =
      mesh.current.rotation.y =
      mesh.current.rotation.z +=
        0.001;
  });
  return (
    <mesh ref={mesh} position={[0, 0, 0]}>
      <RoundedBox args={[30, 30, 30]} radius={2}>
        <meshPhongMaterial attach="material" color={"grey"} />
      </RoundedBox>
    </mesh>
  );
}

function setUpDefaultLoadingManager(progress_bar_ref: any) {
  DefaultLoadingManager.onStart = function (url, itemsLoaded, itemsTotal) {
    console.log(
      "Started loading file: " +
        url +
        ".\nLoaded " +
        itemsLoaded +
        " of " +
        itemsTotal +
        " files."
    );
  };

  DefaultLoadingManager.onLoad = function () {
    console.log("Loading Complete!");
    progress_bar_ref.current.complete();
  };

  DefaultLoadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
    console.log(
      "Loading file: " +
        url +
        ".\nLoaded " +
        itemsLoaded +
        " of " +
        itemsTotal +
        " files."
    );
  };
}

function onDragEnter() {
  console.log("onDragEnter");
}

function MyDropzone(props: any) {
  const setUploaded = props.setUploaded;
  const setUploadedFile = props.setUploadedFile;
  const signed_in: boolean = props.signed_in;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log("signed in?", signed_in);

    // Do something with the files
    console.log("onDrop");
    console.log("accepted file", acceptedFiles[0]);

    var formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    setUploadedFile(acceptedFiles[0]);
    setUploaded(true);

    /*
    fetch(api_url + "upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUploaded(true);
      })
      .catch((err) => console.log(err));
      */
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDragEnter,
    onDrop,
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <p>Drag and drop a file, or click to upload</p>
    </div>
  );
}

function getFoldersDictFromThreeDFiles(threeD_files: ThreeD_File[]) {
  console.log("threeD_files", threeD_files);
  const threeD_folders = threeD_files.map((f) => f["folder"]);
  console.log("threeD_folders", threeD_folders);
  const threeD_folders_unique = Array.from(new Set(threeD_folders));
  const threeD_files_dict: { [folder_name: string]: ThreeD_File[] } = {};
  threeD_folders_unique.forEach((threeD_folder) => {
    const files_in_folder = threeD_files.filter(
      (f) => f["folder"] === threeD_folder
    );
    threeD_files_dict[threeD_folder] = files_in_folder;
  });

  return threeD_files_dict;
}

function uploadFile(
  uploaded_file: File,
  setUploaded: any,
  display_name: string,
  folder: string
) {
  console.log("OOO - display_name", display_name);
  console.log("OOO - folder", folder);

  var formData = new FormData();
  formData.append("file", uploaded_file);
  formData.append("display_name", display_name);
  formData.append("folder", folder);

  fetch(api_url + "upload", {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setUploaded(false);
    })
    .catch((err) => console.log(err));
}

function Upload(props: any) {
  const setUploaded: any = props.setUploaded;
  const uploaded: boolean = props.uploaded;
  const uploaded_file: File = props.uploaded_file;
  const folders: string[] = props.folders;
  const display = uploaded ? "flex" : "none";

  const [display_name, setDisplayName] = useState<string>("");
  const [folder, setFolder] = useState<string>("");

  return (
    <React.Fragment>
      <div className="modal" style={{ display }}>
        <div className="upload_container">
          <div className="upload__title_container"></div>
          <div className="uploaded_file">
            Uploading {uploaded_file && uploaded_file["name"]}{" "}
          </div>
          <br />
          <form onSubmit={() => {}}>
            <label>
              {"Display Name"} <br></br>
              <input
                className="white_input"
                name="Display Name"
                type="text"
                onChange={(e) => {
                  setDisplayName(e.target.value);
                }}
              />
            </label>

            <label>
              {"Folder"} <br></br>
              {folders.map((f) => {
                return (
                  <div>
                    <input
                      type="radio"
                      id={f}
                      name="drone"
                      value={f}
                      style={{ display: "inline" }}
                      onChange={(e) => {
                        setFolder(e.target.value);
                      }}
                    />
                    <label style={{ display: "inline" }}>{f}</label>
                  </div>
                );
              })}
            </label>
          </form>
          <br />
          <div
            className="procedure_item_container"
            onClick={(e) =>
              uploadFile(uploaded_file, setUploaded, display_name, folder)
            }
          >
            Upload
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export function ThreeD() {
  const view = useSelector(select_view);
  // const threeD_files = useSelector(select_threeD_files);]
  const signed_in = useSelector(select_signed_in);

  const [threeD_file_dict, setThreeDFileDict] = useState<{
    [folder_name: string]: ThreeD_File[];
  }>({});
  const [threeD_files, setThreeDFiles] = useState<ThreeD_File[]>([]);
  const [selected_folder, setSelectedFolder] = useState<string>();
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [uploaded_file, setUploadedFile] = useState<File | null>(null);

  const display = view == "threeD" ? "flex" : "none";
  const [mounted, setMounted] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [geometry, setGeometry] = useState<BufferGeometry | null>(null);
  const [loader, setLoader] = useState(new STLLoader());
  const ref = useRef(null);

  setUpDefaultLoadingManager(ref);

  useEffect(() => {
    loader.load(api_url + selected_folder + "/" + fileName, (geo) => {
      setGeometry(geo);
      console.log("setting_geo-", geo);
      setMounted(true);
    });
  }, [fileName]);

  useEffect(() => {
    console.log("uploaded", uploaded);
    getThreeDFiles().then((threeD_files) => {
      const tmp_threeD_file_dict = getFoldersDictFromThreeDFiles(threeD_files);
      console.log("folders_dict: ", tmp_threeD_file_dict);
      setThreeDFileDict(tmp_threeD_file_dict);
      setThreeDFiles(threeD_files);
      // setUploaded(false);
    });
  }, [uploaded]);

  return (
    <div className="threeD_container" style={{ display }}>
      <LoadingBar
        color="#880ed4"
        waitingTime={100}
        loaderSpeed={300}
        ref={ref}
      />

      <Upload
        uploaded={uploaded}
        setUploaded={setUploaded}
        uploaded_file={uploaded_file}
        folders={Object.keys(threeD_file_dict)}
      />

      <div className="threeD_navigation_container">
        <NavigationHeader
          selected_folder={selected_folder}
          setSelectedFolder={setSelectedFolder}
        />
        {!selected_folder &&
          Object.keys(threeD_file_dict).map((threeD_folder) => (
            <ThreeDFolderOption
              folderName={threeD_folder}
              setSelectedFolder={setSelectedFolder}
            />
          ))}
        {selected_folder &&
          threeD_file_dict[selected_folder].map((threeD_file) => (
            <ThreeDFileOption
              fileName={threeD_file["file_name"]}
              setFileName={setFileName}
              progress_bar_ref={ref}
            />
          ))}
        <MyDropzone
          setUploaded={setUploaded}
          setUploadedFile={setUploadedFile}
          signed_in={signed_in}
        />
      </div>

      <Canvas camera={{ position: [0, 0, 80] }}>
        <CameraControls />
        {!mounted && <Box />}
        {mounted && <Mesh geometry={geometry} />}
        <Lighting />
      </Canvas>
    </div>
  );
}
