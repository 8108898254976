import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Filters, getFilters, getFilteredISONums } from "../app/api";
import { RootState } from "../app/store";

const initialState: Filters = {
  status: "idle",
  error: null,
  active_filters: [],
  filter_lookup: {},
  filtered_iso_nums: [],
};

export const fetchFilters = createAsyncThunk(
  "filters/fetchFilters",
  getFilters
);

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    toggle_active_filter: (state, action) => {
      const filter: string = action.payload;
      console.log("active_filters--", state.active_filters);

      if (state.active_filters.includes(filter)) {
        console.log("delete--");
        state.active_filters = state.active_filters.filter((x) => x != filter);
      } else {
        console.log("add--");
        state.active_filters = state.active_filters.concat(filter);
      }
      state.filtered_iso_nums = getFilteredISONums(
        state.active_filters,
        state.filter_lookup
      );

      console.log("active_filters--", state.active_filters);
    },
  },
  extraReducers: {
    [fetchFilters.pending.type]: (state, action) => {
      state.status = "loading";
    },
    [fetchFilters.fulfilled.type]: (state, action) => {
      state.status = "succeeded";
      state.filter_lookup = action.payload; // assign filter_lookup
      console.log("filter");
      // state.active_filters.push("all"); // modify active_filters
      console.log("state.filtered_iso_nums--", state.filtered_iso_nums);
      state.filtered_iso_nums = getFilteredISONums(
        state.active_filters,
        state.filter_lookup
      ); // assign filterered_iso_nums

      console.log("state.filtered_iso_nums--", state.filtered_iso_nums);
    },
    [fetchFilters.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { toggle_active_filter } = filtersSlice.actions;

export const select_filter_lookup = (state: RootState) =>
  state.filters.filter_lookup;
export const select_active_filters = (state: RootState) =>
  state.filters.active_filters;
export const select_filtered_iso_nums = (state: RootState) =>
  state.filters.filtered_iso_nums;
export const select_filters_state = (state: RootState) => state.filters.status;

export default filtersSlice.reducer;
