import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Clinical,
  getTeeth,
  getThreeDFiles,
  Procedure,
  Tooth,
  Sort,
  sort_types,
  getSortFuns,
  extractDate,
} from "../app/api";
import { RootState } from "../app/store";

export const fetchTeeth = createAsyncThunk("teeth/fetchTeeth", getTeeth);
export const fetchThreeDFiles = createAsyncThunk(
  "teeth/fetchThreeDFiles",
  getThreeDFiles
);

const initialState: Clinical = {
  teeth_status: "idle",
  threeD_files_status: "idle",
  error: null,
  teeth: [],
  sort_by_name: "tooth_uns",
  ascending: true,
  procedures: [],
  eval_procedure: null,
  criteria: [],
  threeD_files: [],
};

function extractGrade(procedure: Procedure) {
  return procedure["evaluations"][0]["criteria"][0]["total_grade"];
}

export const clinicalSlice = createSlice({
  name: "clinical",
  initialState,
  reducers: {
    update_criteria: (state, action) => {
      const eval_score = action.payload.eval;
      const idx = action.payload.idx;
      console.log("payload--", eval_score);
      state.criteria[idx]["eval"] = eval_score;
    },
    toggle_upcoming_evaluations: (state, action) => {
      const sort_by_name = state.sort_by_name;
      const sort_name: string = action.payload;
      const sort_funs = getSortFuns(sort_name);
      state.procedures = state.procedures.sort(sort_funs);
    },
    sort_procedures: (state, action) => {
      const sort_name: string = action.payload;
      const sort_funs = getSortFuns(sort_name);
      state.procedures = state.procedures.sort(sort_funs);
    },
    toggle_ascending: (state) => {
      state.ascending = !state.ascending;
      state.procedures = state.procedures.reverse();
    },
    toggle_sort_by_name: (state, action) => {
      state.sort_by_name = action.payload;
      const sort_funs = getSortFuns(state.sort_by_name);
      state.procedures = state.procedures.sort(sort_funs);
    },
    update_procedure: (state, action) => {
      const payload = action.payload;
      console.log("update_procedure--", payload);
    },
    searchProcedure: (state, action) => {
      const due_date_ms: number = action.payload.due_date_ms;
      const tooth_uns: number = action.payload.tooth_uns;
      const name: string = action.payload.name;
      const eval_type: string = action.payload.eval_type;
      console.log("procedure-test", due_date_ms, tooth_uns, name, eval_type);

      const procedures_filtered = state.procedures.filter((p) => {
        //console.log("due_date p--", p["due_date"]["$date"]);
        //console.log("due_date ms--", due_date_ms);

        const is_procedure =
          p["due_date"]["$date"] === due_date_ms &&
          p["tooth_uns"] === tooth_uns &&
          p["name"] === name &&
          p["eval_type"] === eval_type;

        if (is_procedure) {
          console.log(
            "due_date equality--",
            p["due_date"]["$date"] === due_date_ms
          );
          console.log("tooth_uns equality--", p["tooth_uns"] === tooth_uns);
          console.log("name equality--", p["name"] === name);
          console.log("eval_type equality--", p["eval_type"] === eval_type);
          console.log("matched procedure--", p);
        }

        return is_procedure;
      });

      console.log("procedures_filtered", procedures_filtered);

      const eval_procedure = procedures_filtered.length
        ? procedures_filtered[0]
        : null;

      console.log("eval_procedure", eval_procedure);
      state.eval_procedure = eval_procedure;
    },
  },
  extraReducers: {
    [fetchTeeth.pending.type]: (state, action) => {
      state.teeth_status = "loading";
    },
    [fetchTeeth.fulfilled.type]: (state, action) => {
      state.teeth_status = "succeeded";
      console.log("state.teeth", state.teeth);
      state.teeth = action.payload;

      if (state.teeth) {
        console.log("state.teeth", state.teeth);
        state.procedures = generateProcedures(state.teeth);
        state.eval_procedure = state.procedures[0];
        state.criteria = state.eval_procedure["evaluations"][0]["criteria"];
      }
    },
    [fetchTeeth.rejected.type]: (state, action) => {
      state.teeth_status = "failed";
      state.error = action.error.message;
    },
    [fetchThreeDFiles.pending.type]: (state, action) => {
      state.threeD_files_status = "loading";
    },
    [fetchThreeDFiles.fulfilled.type]: (state, action) => {
      state.threeD_files_status = "succeeded";
      console.log("fetchThreeDFiles.fulfilled.type", action.payload);
      state.threeD_files = action.payload;
    },
    [fetchThreeDFiles.rejected.type]: (state, action) => {
      state.threeD_files_status = "failed";
      state.error = action.error.message;
    },
  },
});

/*function searchProcedure(state
  due_date: Procedure["due_date"],
  tooth_uns: Procedure["tooth_uns"],
  procedure_name: Procedure["name"],
  eval_type: Procedure["eval_type"]
) {  

}*/

export const select_teeth = (state: RootState) => state.clinical.teeth;
export const select_maxillary = (state: RootState) =>
  state.clinical.teeth ? state.clinical.teeth.slice(0, 16) : [];
export const select_mandibular = (state: RootState) =>
  state.clinical.teeth ? state.clinical.teeth.slice(-16).reverse() : [];

export const select_procedures = (state: RootState) => {
  return state.clinical.procedures;
};

function generateProcedures(teeth: Tooth[]) {
  if (!teeth) {
    return [];
  }

  return teeth
    .map((t) => t["procedures"])
    .flat()
    .sort();
}

function sortProcedures(sort_by_func: Sort["sort_fun"], teeth: Tooth[]) {
  if (!teeth) {
    return [];
  }

  return teeth
    .map((t) => t["procedures"])
    .flat()
    .sort(sort_by_func);
}

export const select_procedure = (
  state: RootState,
  due_date_ms: number,
  tooth_uns: Procedure["tooth_uns"],
  procedure_name: Procedure["name"],
  eval_type: Procedure["eval_type"]
) =>
  state.clinical.procedures
    .filter(
      (p) =>
        p["due_date"]["$date"] == due_date_ms &&
        p["tooth_uns"] == tooth_uns &&
        p["name"] == procedure_name &&
        p["eval_type"] == eval_type
    )
    .slice(0, 1);

export const select_clinical_teeth_status = (state: RootState) =>
  state.clinical.teeth_status;

export const select_clinical_threeD_files_status = (state: RootState) =>
  state.clinical.threeD_files_status;

export const select_threeD_files = (state: RootState) =>
  state.clinical.threeD_files;

export const select_sort_by_name = (state: RootState) =>
  state.clinical.sort_by_name;

export const select_ascending = (state: RootState) => state.clinical.ascending;

export const select_eval_procedure = (state: RootState) =>
  state.clinical.eval_procedure;

export const select_criteria = (state: RootState) => state.clinical.criteria;

export const select_calculated_grade = (state: RootState) => {
  let grade_sum = 0;
  let num_criteria = state.clinical.criteria.length - 1;
  state.clinical.criteria
    .filter((criterion) => criterion["criterion_name"] != "grade")
    .map((criterion) => (grade_sum += criterion["eval"]));
  return num_criteria != 0 ? grade_sum / (num_criteria * 100) : -1;
};

export const {
  sort_procedures,
  toggle_sort_by_name,
  toggle_ascending,
  update_procedure,
  searchProcedure,
  update_criteria,
} = clinicalSlice.actions;

export default clinicalSlice.reducer;
