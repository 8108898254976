import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { RootState, AppDispatch } from "../app/store";
import {
  getFilteredISONums,
  getGradeColor,
  Insight,
  sort_types,
  getSortFuns,
  extractDate,
} from "../app/api";

import {
  select_filter_lookup,
  toggle_active_filter,
  select_active_filters,
  select_filtered_iso_nums,
  fetchFilters,
  select_filters_state,
} from "../slices/filtersSlice";
import {
  sign_in,
  sign_out,
  select_signed_in,
  select_choosing_filters,
  select_choosing_sort,
  toggle_choosing_filters,
  toggle_choosing_sort,
  select_view,
} from "../slices/userSlice";
import {
  select_teeth,
  select_procedures,
  sort_procedures,
  select_sort_by_name,
  select_clinical_teeth_status,
  select_ascending,
  toggle_sort_by_name,
  fetchTeeth,
  toggle_ascending,
} from "../slices/clinicalSlice";
import { Teeth } from "./teeth";
import { Filters, getFilters, Procedure, Sort } from "../app/api";

function addToGradeMaps(
  procedure: Procedure,
  filter_lookup: Filters["filter_lookup"],
  procedureFilterGradeMap: { [key: string]: { [key: string]: number[] } },
  filterProceduresGradeMap: {
    [key: string]: { [key: string]: number[] };
  }
) {
  const total_grade = procedure["evaluations"][0]["criteria"][0]["total_grade"];
  const filter_names = filter_lookup ? Object.keys(filter_lookup) : [];

  filter_names.map((filter_name) => {
    // console.log("insight");
    const specific_filtered_iso_nums = getFilteredISONums(
      [filter_name],
      filter_lookup
    );
    const is_relevant_filter = specific_filtered_iso_nums.includes(
      procedure["tooth_iso"]
    );

    if (is_relevant_filter) {
      const filterGradeMap = procedureFilterGradeMap[procedure["name"]];
      if (!filterGradeMap) procedureFilterGradeMap[procedure["name"]] = {};

      const grades = procedureFilterGradeMap[procedure["name"]][filter_name];
      procedureFilterGradeMap[procedure["name"]][filter_name] = grades
        ? grades.concat(total_grade)
        : [total_grade];
    }
  });
}

function getUpcomingDiv(
  name: string,
  due_date: Date,
  insights_by_weakest: Insight[],
  tooth_iso: number,
  tooth_uns: number,
  filter_lookup: Filters["filter_lookup"]
) {
  const formatted_date = `${
    due_date.getMonth() + 1
  }/${due_date.getDate()}/${due_date.getFullYear()}`;

  const mapped_insights = insights_by_weakest.map((x) => x["avg_grade"]);
  console.log("mapped_insights", mapped_insights);

  const filtered_insights = insights_by_weakest.filter((x) => {
    return getFilteredISONums([x["filter_name"]], filter_lookup).includes(
      tooth_iso
    );
  });

  return (
    <div className="insight_container">
      <div className="insight_title_container">
        <div className="upcoming_title_container">
          <div className="procedure_item_container_insight">{name}</div>
          <div className="procedure_item_container_insight">#{tooth_uns}</div>
          <div className="procedure_item_container_insight">
            {formatted_date}
          </div>
        </div>
      </div>
      <div className="insight_body_container">
        <div className="insight_eval_container"></div>
        <div className="insight_filter_row_container">
          {getDualElementContainer(
            "Predicted Score",
            filtered_insights
              .map((x) => x["avg_grade"])
              .reduce((prev, cur) => prev + cur, 0) / filtered_insights.length
          )}
          {filtered_insights.map((x) =>
            getDualElementContainer(x["filter_name"], x["avg_grade"])
          )}
        </div>
      </div>
      <div className="insight_footer_container button">Practice</div>
    </div>
  );
}

function UpcomingEvaluationsInsight() {
  const [threshold_num] = useState(7);
  const [max_procedures] = useState(5);

  const procedures = useSelector(select_procedures);
  const filter_lookup = useSelector(select_filter_lookup);
  const ascending = useSelector(select_ascending);
  let procedures_by_due_date = [...procedures].sort(getSortFuns("due_date"));

  if (!ascending) {
    procedures_by_due_date = procedures_by_due_date.reverse();
  }

  procedures_by_due_date = procedures_by_due_date
    .filter((x) => {
      const procedure_due_date = extractDate(x);
      const threshold = new Date();
      const threshold_date = threshold.getDate();
      const threshold_month = threshold.getMonth();

      threshold.setDate(threshold_date + threshold_num);
      if (threshold.getDate() < threshold_date) {
        threshold.setMonth(threshold_month + 1);
      }

      return new Date() < procedure_due_date && procedure_due_date < threshold;
    })
    .slice(0, max_procedures);

  const insights_by_weakest = getWeakestFilters(procedures, filter_lookup);

  return procedures_by_due_date.map((x) =>
    getUpcomingDiv(
      x["name"],
      extractDate(x),
      insights_by_weakest.filter((y) => y["procedure_name"] == x["name"]),
      x["tooth_iso"],
      x["tooth_uns"],
      filter_lookup
    )
  );
}

function getWeakestFilters(
  procedures: Procedure[],
  filter_lookup: Filters["filter_lookup"]
) {
  const procedureFiltersGradeMap: {
    [key: string]: { [key: string]: number[] };
  } = {};

  const filterProceduresGradeMap: {
    [key: string]: { [key: string]: number[] };
  } = {};

  procedures.forEach((x) => {
    addToGradeMaps(
      x,
      filter_lookup,
      procedureFiltersGradeMap,
      filterProceduresGradeMap
    );
  });

  const relevant_procedure_names = procedureFiltersGradeMap
    ? Object.keys(procedureFiltersGradeMap)
    : [];

  const filters_by_weakness: Insight[] = [];

  relevant_procedure_names.forEach((procedure_name) => {
    const filterGradeMap = procedureFiltersGradeMap[procedure_name];
    const relevant_filter_names = filterGradeMap
      ? Object.keys(filterGradeMap)
      : [];
    relevant_filter_names.forEach((filter_name) => {
      const insight = {
        filter_name: filter_name,
        avg_grade:
          Math.round(
            (filterGradeMap[filter_name].reduce(
              (a: number, b: number) => a + b,
              0
            ) /
              filterGradeMap[filter_name].length) *
              100 +
              Number.EPSILON
          ) / 100,
        procedure_name: procedure_name,
      };
      filters_by_weakness.push(insight);
    });
  });

  const sorted_insight = filters_by_weakness.sort((a, b) => {
    if (a["avg_grade"] < b["avg_grade"]) {
      return -1;
    } else if (a["avg_grade"] > b["avg_grade"]) {
      return 1;
    }
    return 0;
  });

  //console.log("sorted_insight--", sorted_insight);
  return sorted_insight;
}

function getWeakestFiltersDiv(
  procedure_name: string,
  filter_name: string,
  avg_grade: number
) {
  return (
    <div className="insight_filter_row_container">
      <div className="procedure_item_container_insight">{procedure_name}</div>
      {getDualElementContainer(filter_name, avg_grade)}
    </div>
  );
}

function getDualElementContainer(filter_name: string, avg_grade: number) {
  const avg_grade_rounded = parseFloat(avg_grade.toFixed(2));
  return (
    <div className="dual_element_container">
      <div className="procedure_item_insight">{filter_name}</div>
      <div
        className="procedure_item_insight"
        style={{
          backgroundColor: getGradeColor(avg_grade_rounded),
          color: "#333333",
          fontWeight: "bold",
        }}
      >
        {avg_grade_rounded}%
      </div>
    </div>
  );
}

function WeakestInsight() {
  const procedures = useSelector(select_procedures);
  const filter_lookup = useSelector(select_filter_lookup);

  return (
    <div className="insight_container">
      <div className="insight_title_container">Weakest Areas</div>
      <div className="insight_body_container">
        {getWeakestFilters(procedures, filter_lookup)
          .slice(0, 3)
          .map((x) =>
            getWeakestFiltersDiv(
              x["procedure_name"],
              x["filter_name"],
              x["avg_grade"]
            )
          )}
      </div>
      <div className="insight_footer_container button">Practice</div>
    </div>
  );
}

function StrongestInsight() {
  const procedures = useSelector(select_procedures);
  const filter_lookup = useSelector(select_filter_lookup);

  return (
    <div className="insight_container">
      <div className="insight_title_container">Strongest Areas</div>
      <div className="insight_body_container">
        {getWeakestFilters(procedures, filter_lookup)
          .reverse()
          .slice(0, 3)
          .map((x) =>
            getWeakestFiltersDiv(
              x["procedure_name"],
              x["filter_name"],
              x["avg_grade"]
            )
          )}
      </div>
      <div className="insight_footer_container button">See More Strengths</div>
    </div>
  );
}

function ExplanationInsight() {
  const procedures = useSelector(select_procedures);
  const filter_lookup = useSelector(select_filter_lookup);

  return (
    <div className="insight_container">
      <div className="insight_title_container">What are Insights?</div>
      <div className="insight_body_container insight_explanation">
        Insights make it easy for you to understand and target areas for
        improvement.
      </div>
      <div className="insight_footer_container button">Suggest an Insight</div>
    </div>
  );
}

function EmptyInsight() {
  const procedures = useSelector(select_procedures);
  const filter_lookup = useSelector(select_filter_lookup);

  return (
    <div className="insight_container">
      <div className="insight_title_container">Choose Insight</div>
      <div className="insight_body_container">{}</div>
      <div className="insight_footer_container"></div>
    </div>
  );
}

export function Insights() {
  const dispatch = useDispatch();
  const filtered_iso_nums = useSelector(select_filtered_iso_nums);

  const view = useSelector(select_view);
  const display = view == "insights" ? "flex" : "none";

  return (
    <React.Fragment>
      <div className="insights_container" style={{ display }}>
        <ExplanationInsight />
        <WeakestInsight />
        <StrongestInsight />
        {UpcomingEvaluationsInsight()}
      </div>
    </React.Fragment>
  );
}
