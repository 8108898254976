import "./App.css";
import React from "react";
import { Provider, useSelector } from "react-redux";
import { store } from "./app/store";
import { Navbar } from "./components/navbar";
import { Setup } from "./components/setup";
import { Teeth } from "./components/teeth";
import { Procedures } from "./components/procedures";
import { Insights } from "./components/insights";
import { Create } from "./components/create";
import { ThreeD } from "./components/threeD";
import { Camera } from "./components/camera";
import Signin from "./components/signin";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import {
  sign_in,
  sign_out,
  select_signed_in,
  toggle_signed_in,
  toggle_signing_in,
  toggle_view,
  select_view,
} from "./slices/userSlice";

const state = store.getState();
console.log("pre-app state--", state);

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Setup />
        <Signin />
        <Navbar />
        <Teeth />
        <Procedures />
        <Insights />
        <Create />
        <ThreeD />
        <Camera />
      </Provider>
    </React.StrictMode>
  );
}

export default App;
