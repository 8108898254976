import React, { MouseEventHandler, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { select_filtered_iso_nums } from "../slices/filtersSlice";
import { select_view } from "../slices/userSlice";
import {
  select_procedures,
  select_eval_procedure,
  select_calculated_grade,
  select_criteria,
  update_criteria,
} from "../slices/clinicalSlice";

import { Procedure, getGradeColor } from "../app/api";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function get_formatted_date_from_ms(ms: number) {
  const date_time = new Date(ms);
  console.log("date_time--", date_time);
  return `${
    date_time.getMonth() + 1
  }/${date_time.getDate()}/${date_time.getFullYear()}`;
}

function getCalendarDate(eval_procedure: Procedure | null) {
  if (!eval_procedure) {
    return "";
  }

  const ms_time = eval_procedure["due_date"]["$date"];
  const date_time = new Date(ms_time);
  console.log("date_time--", date_time);
  return `${
    date_time.getMonth() + 1
  }/${date_time.getDate()}/${date_time.getFullYear()}`;
}

function getSelText(e: any) {
  console.log("hilooooo");
  console.log("window--", window.getSelection());
}

// http://localhost:3000/?due_date_ms=1631232000000&tooth_uns=1&name=class%20V%20cavity%20rest&eval_type=practical
export function Create() {
  const dispatch = useDispatch();
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string);
  let due_date_ms = url.searchParams.get("due_date_ms");

  console.log(due_date_ms);
  let tooth_uns = url.searchParams.get("tooth_uns");
  console.log(tooth_uns);
  let name = url.searchParams.get("name");
  console.log(name);
  let eval_type = url.searchParams.get("eval_type");
  console.log(eval_type);

  // dispatch(searchProcedure({ due_date_ms, tooth_uns, name, eval_type }));

  const eval_procedure = useSelector(select_eval_procedure);
  const criteria = useSelector(select_criteria);
  const calculated_grade = useSelector(select_calculated_grade);

  const view = useSelector(select_view);
  const display = view == "create" ? "flex" : "none";
  console.log("criteria---", criteria);

  const criteria_copy = criteria.map((criterion) => {
    return Object.assign({}, criterion);
  });

  console.log("criteria_copy", criteria_copy);
  const [startDate, setStartDate] = useState<any>(new Date());

  return (
    <React.Fragment>
      <div className="create_container" style={{ display }}>
        <div className="create_nav_container">
          <DatePicker
            className="create_item_container"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />

          <div className="create_item_container">
            {eval_procedure && eval_procedure["eval_type"]}
          </div>
          <div className="create_item_container">
            {eval_procedure && eval_procedure["name"]}
          </div>
          <div className="create_item_container">
            #{eval_procedure && eval_procedure["tooth_uns"]}
          </div>
        </div>
        <div className="criteria_container">
          {criteria
            .filter((criterion) => criterion["criterion_name"] != "grade")
            .map((criterion, idx) => {
              const criterion_grade = 50;
              const visibility = criterion["rubric_row"].length
                ? "visible"
                : "hidden";

              return (
                <div className="criterion_container">
                  <div className="criterion_item_container">
                    {criterion["category"]}
                  </div>
                  <div className="criterion_item_container">
                    {criterion["criterion_name"]}
                  </div>

                  <div className="rubric_row">
                    <div className="criterion_item_container eval_container">
                      <div
                        onClick={(e) =>
                          dispatch(update_criteria({ eval: 100.0, idx: idx }))
                        }
                        className="eval_item_container E_grade"
                        style={{
                          backgroundColor:
                            criteria[idx]["eval"] == 100.0
                              ? "#444444"
                              : "#666666",
                        }}
                      >
                        E
                      </div>
                      <div
                        onClick={(e) =>
                          dispatch(update_criteria({ eval: 70.0, idx: idx }))
                        }
                        className="eval_item_container S_grade"
                        style={{
                          backgroundColor:
                            criteria[idx]["eval"] == 70.0
                              ? "#444444"
                              : "#666666",
                        }}
                      >
                        S
                      </div>
                      <div
                        onClick={(e) =>
                          dispatch(update_criteria({ eval: 0.0, idx: idx }))
                        }
                        className="eval_item_container U_grade"
                        style={{
                          backgroundColor:
                            criteria[idx]["eval"] == 0.0
                              ? "#444444"
                              : "#666666",
                        }}
                      >
                        U
                      </div>
                    </div>

                    <textarea
                      name="text"
                      className="criterion_item_container rubric self_eval_container"
                      defaultValue={"remarks"}
                    />
                    <div
                      className="criterion_item_container rubric"
                      style={{
                        visibility,
                        backgroundColor:
                          criteria[idx]["eval"] == 100.0
                            ? "#444444"
                            : "#666666",
                      }}
                    >
                      {criterion["rubric_row"][0]}
                    </div>
                    <div
                      className="criterion_item_container rubric"
                      style={{
                        visibility,
                        backgroundColor:
                          criteria[idx]["eval"] == 70.0 ? "#444444" : "#666666",
                      }}
                    >
                      {criterion["rubric_row"][1]}
                    </div>
                    <div
                      className="criterion_item_container rubric"
                      style={{
                        visibility,
                        backgroundColor:
                          criteria[idx]["eval"] == 0.0 ? "#444444" : "#666666",
                      }}
                    >
                      {criterion["rubric_row"][2]}
                    </div>
                  </div>
                </div>
              );
            })}
          {criteria
            .filter((criterion) => criterion["criterion_name"] == "grade")
            .map((criterion, idx) => {
              const criterion_grade = 50;
              const visibility = criterion["rubric_row"].length
                ? "visible"
                : "hidden";

              return (
                <div className="criterion_container">
                  <div className="criterion_item_container">
                    {criterion["category"]}
                  </div>
                  <div className="criterion_item_container">
                    {criterion["criterion_name"]}
                  </div>

                  <div className="rubric_row">
                    <div
                      className="criterion_item_container grade_container"
                      style={{
                        backgroundColor: getGradeColor(calculated_grade * 100),
                        color: "#333333",
                        fontWeight: "bold",
                      }}
                    >
                      {calculated_grade >= 0 && calculated_grade * 100}%
                    </div>

                    <textarea
                      name="text"
                      className="criterion_item_container rubric self_eval_container"
                      defaultValue={"remarks"}
                    />
                    <div
                      className="criterion_item_container rubric"
                      onClick={getSelText}
                      onMouseDown={getSelText}
                      style={{
                        visibility,
                        backgroundColor:
                          criteria[idx]["eval"] == 100.0
                            ? "#444444"
                            : "#666666",
                      }}
                    >
                      {criterion["rubric_row"][0]}
                    </div>
                    <div
                      className="criterion_item_container rubric"
                      style={{
                        visibility,
                        backgroundColor:
                          criteria[idx]["eval"] == 70.0 ? "#444444" : "#666666",
                      }}
                    >
                      {criterion["rubric_row"][1]}
                    </div>
                    <div
                      className="criterion_item_container rubric"
                      style={{
                        visibility,
                        backgroundColor:
                          criteria[idx]["eval"] == 0.0 ? "#444444" : "#666666",
                      }}
                    >
                      {criterion["rubric_row"][2]}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}
